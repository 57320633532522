import React from 'react';
import styled from 'styled-components';

const IconContainer = ({children, name, darkText}) => (
  <Container>
    {children}
    <Subtitle darkText={darkText}>{name}</Subtitle>
  </Container>
)

const Container = styled.div`
  /* width: 100%; */
  max-width: 400px;
  max-height: 400px;
  /* margin-right: auto;
  margin-left: auto; */
  padding-right: 50px;
  padding-left: 50px;
  display: flex;
  flex-flow: column;
  align-items: center;
  color: #010606;

  @media screen and (max-width: 500px) {
    padding-right: 20px;
    padding-left: 20px;
  }
`;

const Subtitle = styled.p`
  max-width: 440px;
  /* margin-bottom: 35px; */
  font-size: 18px;
  line-height: 24px;
  color: ${({ darkText }) => (darkText ? '#010606' : '#010606')};
`;

export default IconContainer;