import React from 'react';
import { Button } from '../ButtonElements';
import { GrMysql, GrUbuntu } from 'react-icons/gr';
import { AiFillGithub } from 'react-icons/ai';
import { FaNodeJs, FaSass } from 'react-icons/fa';
import IconContainer from './IconContainer';
import { SiWebrtc, SiUbuntu, SiBootstrap, SiJest, SiReact, SiCss3, SiHtml5, SiJava, SiJavascript, SiCplusplus, SiFirebase, SiMongodb, SiBabel, SiWebpack, SiRedux, SiApollographql, SiGraphql, SiFigma, SiTypescript } from 'react-icons/si';
import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  BtnWrap,
  ImgWrap,
  Img,
  Container
} from './SkillElements';

const SkillSection = ({
  lightBg,
  imgStart,
  topLine,
  lightText,
  headline,
  description,
  buttonLabel,
  img,
  alt,
  id,
  primary,
  darkText,
  dark,
  dark2
}) => {
  console.log(primary);
  const mapIcons1 = [
    {name: 'Javascript', icon: "fab fa-js-square fa-5x"},
    {name: 'Java', icon: "fab fa-java fa-5x"},
    {name: 'React.js', icon: "fab fa-react fa-5x"},
    {name: 'Node.js', icon: "fab fa-node-js fa-5x"},
    {name: 'HTML5', icon: "fab fa-html5 fa-5x"},
    {name: 'CSS3', icon: "fab fa-css3 fa-5x"},
    {name: 'Bootstrap', icon: "fab fa-bootstrap fa-5x"},
  ];
  const mapIcons2 = [
    {name: 'Javascript', icon: <SiJavascript style={{fontSize: '5em'}} />},
    {name: 'Typescript', icon: <SiTypescript style={{fontSize: '5em'}} />},
    {name: 'Java', icon: <SiJava style={{fontSize: '5em'}} />},
    {name: 'C/C++', icon: <SiCplusplus style={{fontSize: '5em'}} />},
    {name: 'HTML5', icon: <SiHtml5 style={{fontSize: '5em'}} />},
    {name: 'CSS3', icon: <SiCss3 style={{fontSize: '5em'}} />},
    {name: 'React.js', icon: <SiReact style={{fontSize: '5em'}} />},
    {name: 'Node.js', icon: <FaNodeJs style={{fontSize: '5em'}} />},
    {name: 'Redux', icon: <SiRedux style={{fontSize: '5em'}} />},
    {name: 'SASS', icon: <FaSass style={{fontSize: '5em'}} />},
    {name: 'Bootstrap', icon: <SiBootstrap style={{fontSize: '5em'}} />},
    {name: 'Jest', icon: <SiJest style={{fontSize: '5em'}} />},
    {name: 'Firebase', icon: <SiFirebase style={{fontSize: '5em'}} />},
    {name: 'MongoDB', icon: <SiMongodb style={{fontSize: '5em'}} />},
    {name: 'MySQL', icon: <GrMysql style={{fontSize: '5em'}} />},
    {name: 'Git', icon: <AiFillGithub style={{fontSize: '5em'}} />},
    {name: 'Babel', icon: <SiBabel style={{fontSize: '5em'}} />},
    {name: 'Webpack', icon: <SiWebpack style={{fontSize: '5em'}} />},
    {name: 'WebRTC', icon: <SiWebrtc style={{fontSize: '5em'}} />},
    {name: 'Ubuntu', icon: <SiUbuntu style={{fontSize: '5em'}} />},
    // {name: 'Apollo', icon: <SiApollographql style={{fontSize: '5em'}} />},
    // {name: 'GraqhQL', icon: <SiGraphql style={{fontSize: '5em'}} />},
    // {name: 'Ubuntu', icon: <GrUbuntu style={{fontSize: '5em'}} />},
    // {name: 'Figma', icon: <SiFigma style={{fontSize: '5em'}} />},
  ];
  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
        <Heading lightText={lightText} style={{textAlign: "center", fontSize: "48px"}}>Technical Skills</Heading>

        <Container style={{display: 'flex', flexFlow: 'row wrap', justifyContent: 'space-evenly'}}>
        {
          mapIcons2.map(icon => 
          <IconContainer name={icon.name}  darkText={true}>
              {icon.icon}
          </IconContainer>)
        }
        </Container>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default SkillSection;
