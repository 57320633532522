import React from 'react';
import { PureButton, Button } from '../ButtonElements';
import Resume from './Resume_LeimingCai.pdf';
import {
  FaFacebook,
  FaLinkedin,
  FaGithub
} from 'react-icons/fa';
import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  BtnWrap,
  ImgWrap,
  Img,
  SocialIcons,
  SocialIconLink
} from './AboutElements';

const AboutSection = ({
  lightBg,
  imgStart,
  topLine,
  lightText,
  headline,
  description,
  buttonLabel,
  img,
  alt,
  id,
  primary,
  darkText,
  dark,
  dark2
}) => {
  console.log(primary);
  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <Heading lightText={lightText}>{headline}</Heading>
                {/* <TopLine>{topLine}</TopLine> */}
                <Subtitle darkText={darkText}>
                <p>Hi! I'm Leiming, and I'm a software developer who has a passion for building clean and sustainable applications and systems with easy-to-use functionality. I enjoy the process of turning ideas into reality using creative solutions and implementing alongside good teammates. I am also self-motivated, like new challenges, and able to operate under a great deal of independence. I am presently looking to turn my passion for software development into my career.</p>
                <br />
                <p>Trained as a material scientist, my current  work as a reliability and test engineer at a semiconductor company consists of VB6 and C programming. As my interest in programming grew into interest in application development, I decided to pivot my career as I am now.</p>                
                <br />
                <p>To that end, I enrolled at courses at Georgia Tech, culminating in a master’s degree in computer science, and built apps with my teammates. Thanks to the guidance of my professors, mentors, and teammates, I am  proud of my generalist knowledge  and practical experiences, which now span software engineering, web application development, backend development, RESTful APIs, frontend/user interface development, game development, and operating system.</p>
                </Subtitle>
              </TextWrapper>
             
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={img} alt={alt} />
              </ImgWrap>
              <TextWrapper>
              <SocialIcons>
                  <SocialIconLink href='https://www.facebook.com/profile.php?id=100006338172955' target='_blank' aria-label='Facebook'>
                    <FaFacebook />
                  </SocialIconLink>
                  <SocialIconLink href='https://www.linkedin.com/in/leimingcai/' target='_blank' aria-label='LinkedIn'>
                    <FaLinkedin />
                  </SocialIconLink>
                  <SocialIconLink href='https://github.com/leimingc' target='_blank' aria-label='Github'>
                    <FaGithub />
                  </SocialIconLink>
                </SocialIcons>
                </TextWrapper>
              <BtnWrap>
                  <Button
                    to='contact'
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact='true'
                    offset={-80}
                    primary={primary ? 1 : 0}
                    dark={dark ? 1 : 0}
                    dark2={dark2 ? 1 : 0}
                  >
                    Let's talk!
                  </Button>
                  <a href = {Resume} target = "_blank" style={{textDecoration: 'none'}}>
                  <PureButton
                    // to='home'
                    // smooth={true}
                    // duration={500}
                    // spy={true}
                    // exact='true'
                    // offset={-80}
                    primary={primary ? 1 : 0}
                    dark={dark ? 1 : 0}
                    dark2={dark2 ? 1 : 0}
                  >
                    Resume
                  </PureButton>
                  </a>
                </BtnWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default AboutSection;
