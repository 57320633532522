import React from 'react';
import { Button } from '../ButtonElements';
import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  BtnWrap,
  ImgWrap,
  Img,
  Container
} from './ExpElements';

const ExpSection = ({
  lightBg,
  imgStart,
  topLine,
  lightText,
  headline,
  description,
  buttonLabel,
  img,
  alt,
  id,
  primary,
  darkText,
  dark,
  dark2
}) => {
  console.log(primary);
  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        {/* <InfoWrapper> */}
        <Container>
        <Heading lightText={lightText} style={{textAlign: "center", fontSize: "48px", color: '#fff'}}>Experiences</Heading>
                <TextWrapper>
                <div style={{display: "flex", justifyContent: "space-between", marginBottom: '0px'}}>
                  <Heading lightText={lightText}>Software Engineer</Heading>
                  <Subtitle darkText={darkText} style={{color: '#01BF71'}}>Jun. 2020 - Present</Subtitle>
                </div>
                  <Subtitle darkText={darkText}>Self-employed, Part-time</Subtitle>
                  <Subtitle darkText={darkText}>
                    <ul>
                      <li>Continuously develop and maintain OpenABA web app with my teammates.</li>
                      <li>Established new web game as an tool for online therapy and education.</li>
                      <li>Maintain an active clinician user population of more than 1000.</li>
                    </ul>
                  </Subtitle>
                  <div style={{display: "flex", justifyContent: "space-between"}}>
                  <Heading lightText={lightText}>Test and Reliability Engineer</Heading>
                  <Subtitle darkText={darkText} style={{color: '#01BF71'}}>Sep. 2017 - Present</Subtitle>
                  </div>    
                  <Subtitle darkText={darkText}>Global Communication Semiconductor</Subtitle>            
                  <Subtitle darkText={darkText}>
                    <ul>
                      <li>Implement, maintain and debug code for data analysis programs, electrical test programs and scripts using VB6, C and VBA.</li>
                      <li>Summarize data and furnish the qualification reports for the semiconductor development process.</li>
                      <li>Communicate with customer to provide product information, interface with vendor for equipment support and upgrade.</li>
                    </ul>
                  </Subtitle>
                </TextWrapper>
        </Container>
      </InfoContainer>
    </>
  );
};

export default ExpSection;
