import React from 'react';
import {Card} from 'react-bootstrap'
import { PureButton, DisabledButton } from '../ButtonElements';
import Icon1 from '../../images/svg-1.svg';
import Icon2 from '../../images/svg-3.svg';
import Icon3 from '../../images/svg-5.svg';
import {
  ServicesContainer,
  Heading,
  PricingSection,
  PricingWrapper,
  PricingHeading,
  PricingContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP
} from './ServicesElements';

const Services = () => {
  return (
    <ServicesContainer id='services'>
      {/* <PricingSection> */}
        <PricingWrapper>
        <Heading lightText={true} style={{textAlign: "center", fontSize: "48px"}}>Projects</Heading>
          <PricingContainer>
              <Card style={{ width: '22rem', margin: '10px', height: '540px'}}>
                {/* <Card.Img variant="top" src="https://challengepost-s3-challengepost.netdna-ssl.com/photos/production/software_photos/001/197/973/datas/original.jpg" /> */}
                <Card.Img variant="top" src="https://i.postimg.cc/YSyX6vv8/openaba.jpg" />
                <Card.Body>
                  <Card.Title>Real-time Webapp for remote teaching and therapy</Card.Title>
                  <Card.Text style={{paddingLeft: '10px'}}>
                    <ul>
                      <li>
                        OpenABA is developed to support behavioral health professionals deliver therapy remotely and intuitively to their patients while socially distanced.
                      </li>
                      <li>
                        <strong>Won Virtual Hackathon 2020.</strong>
                      </li>
                    </ul>
                  </Card.Text>
                  <a href='https://www.openaba.com' target="_blank">
                    <PureButton variant="primary" style={{position: "absolute", bottom: "15px"}}>Go to OpenABA</PureButton>
                  </a>
                </Card.Body>
              </Card>
              <Card style={{ width: '22rem', margin: '10px', height: '540px' }}>
              <Card.Img variant="top" src="https://i.postimg.cc/qRvKp0gz/phasergame.png" />
              <Card.Body>
                <Card.Title>Building a real-time online multiplayer game for therapy and education</Card.Title>
                <Card.Text style={{paddingLeft: '10px'}}>
                <ul>
                    <li>
                      Designed and implemented for multi-player communication and education with React, Phaser3 and Colyseus Server.        
                    </li>
                    <li>
                      Includes video and text chat, a wide range of character, hats and items selections in the game.       
                    </li>
                </ul>
                </Card.Text>
                <a href='http://togetherrooms.com/' target="_blank">
                    <DisabledButton variant="primary" disabled style={{position: "absolute", bottom: "15px"}}>Coming soon</DisabledButton>
                  </a>
              </Card.Body>
            </Card>
              {/* <Card style={{ width: '22rem', margin: '10px', height: '540px' }}>
              <Card.Img variant="top" src="https://i.postimg.cc/2ydSPmKX/ios.png" />
              <Card.Body>
                <Card.Title>Building a simplified web server and Distributed File System</Card.Title>
                <Card.Text>
                <ul>
                    <li>
                      Designed and implemented a multi-threaded web server that serves static files based on a GetFile protocol. Alongside the server, created a multi-threaded client that acts as a load generator for the server.        
                    </li>
                    <li>
                      Converted this implementation of the getfile server to act as a proxy server. 
                    </li>
                    <li>
                      Established a simple cache server that does Inter-Process Communication with the proxy via shared memory.
                    </li>
                    <li>
                      Developed several file transfer protocols using gRPC and Protocol Buffers
                    </li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card> */}
              <Card style={{ width: '22rem', margin: '10px', height: '540px'}}>
                <Card.Img variant="top" src="https://i.postimg.cc/Vs0WkrdF/crown-Shop.jpg" />
                <Card.Body>
                  <Card.Title>A shopping website <strong>Crown-clm Clothing</strong></Card.Title>
                  <Card.Text style={{paddingLeft: '10px'}}>
                  <ul>
                      <li>
                        Developed using JavaScript (ES6) and React.js for front-end, Nodejs and Express for back-end, utilized firebase for authentication and data storing, used Redux to maintain cart and current user states, integrated Stripe for checkout payment, deployed by Heroku.                      </li>
                    </ul>
                  </Card.Text>
                  <a href='https://crwn-clm.herokuapp.com/' target="_blank">
                    <PureButton variant="primary" style={{position: "absolute", bottom: "15px"}}>Go to Demo</PureButton>
                  </a>
                </Card.Body>
              </Card>
              <Card style={{ width: '22rem', margin: '10px', height: '470px' }}>
                <Card.Img variant="top" src="https://i.postimg.cc/DZxr8jZM/animal-Heaven.png" />
                <Card.Body>
                  <Card.Title>A shelter website <strong>Inge’s Animal Heaven</strong></Card.Title>
                  <Card.Text style={{paddingLeft: '10px'}}>
                    <ul>
                      <li>
                      Full-stack web application for animal shelter management with React, Nodejs, express and MySQL
                      </li>
                      <li>
                      Shelter data summary, classification and report generation using SQL queries.
                      </li>
                    </ul>
                  </Card.Text>
                  <a href='https://tranquil-badlands-91461.herokuapp.com/' target="_blank">
                  <PureButton variant="primary" style={{position: "absolute", bottom: "15px"}}>Go to Demo</PureButton>
                  </a>
                </Card.Body>
              </Card>
            <Card style={{ width: '22rem', margin: '10px', height: '470px' }}>
              <Card.Img variant="top" src="https://i.postimg.cc/L86SR35h/yelpCamp.png" />
              <Card.Body>
                <Card.Title>A Yelp-like app for camping</Card.Title>
                <Card.Text style={{paddingLeft: '10px'}}>
                  <ul>
                    <li>
                      Built with HTML5, CSS3, Bootstrap4, AJAX and MongoDB.
                    </li>
                    <li>
                    Includes features of registration, authentication, Image Upload and Storage, adding new camp ground and comment. 
                    </li>
                  </ul>
                </Card.Text>
                <a href='https://fast-retreat-74752.herokuapp.com/' target="_blank">
                <PureButton variant="primary" style={{position: "absolute", bottom: "15px"}}>Go to Demo</PureButton>
                </a>
              </Card.Body>
            </Card>
            <Card style={{ width: '22rem', margin: '10px', height: '470px' }}>
              <Card.Img variant="top" src="https://i.postimg.cc/3xBN7ZTc/bearmap.png" />
              <Card.Body>
                <Card.Title>Berkeley Map: A map like Google Map</Card.Title>
                <Card.Text style={{paddingLeft: '10px'}}>
                <ul>
                    <li>
                      Implement Map Rastering, Routing and Autocompletion for the map in back-end using Java                
                    </li>
                  </ul>
                </Card.Text>
                <a href='http://bearmaps-lc.herokuapp.com/' target="_blank">
                <PureButton variant="primary" style={{position: "absolute", bottom: "15px"}}>Go to Demo</PureButton>
                </a>
              </Card.Body>
            </Card>
            
          </PricingContainer>
        </PricingWrapper>
      {/* </PricingSection> */}

      {/* <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src="https://i.postimg.cc/YSyX6vv8/openaba.jpg" />
          <ServicesH2>Reduce Expenses</ServicesH2>
          <ServicesP>
            We help reduce your fees and increase your overall revenue.
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon2} />
          <ServicesH2>Virtual Offices</ServicesH2>
          <ServicesP>
            You can access our platform online anywhere in the world.
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon3} />
          <ServicesH2>Premium Benefits</ServicesH2>
          <ServicesP>
            Unlock our special membership card that returns 5% cash back.
          </ServicesP>
        </ServicesCard>
      </ServicesWrapper> */}
    </ServicesContainer>
  );
};

export default Services;
