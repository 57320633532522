export const homeObjOne = {
  id: 'experiences',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Premium Bank',
  headline: 'Unlimited Transactions with zero fees',
  description:
    'Get access to our exclusive app that allows you to send unlimited transactions without getting charged any fees',
  buttonLabel: 'Get Started',
  imgStart: false,
  img: require('../../images/svg-1.svg'),
  alt: 'Car',
  dark: true,
  primary: true,
  darkText: false
};

export const homeObjTwo = {
  id: 'about',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Seeking opportunities of Software Development Engineer',
  headline: 'I am a software developer',
  description:
    "I am a part-time master student in computer science in Georgia Institute of Technology, and also an electrical and test engineer in a semiconductor company. Part of my work is related to VB6 and C programming, that's why I started to grow interest in computer science. I have broad knowledge in web development, operating system and computer network. Web development and distributed system are my center of interest, i always love the idea of easy-to-use and beautiful web app, cross-platform development, which web technology like Javascript enables me to do. ",
  buttonLabel: 'Learn More',
  imgStart: true,
  img: require('../../images/IMG_0267.JPG'),
  alt: 'Piggybank',
  dark: false,
  primary: false,
  darkText: true
};

export const homeObjThree = {
  id: 'signup',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Join our Team',
  headline: 'Creating an account is extremely easy',
  description:
    "Get everything set up and ready in under 10 minutes. All you need to do is add your information and you're ready to go.",
  buttonLabel: 'Start Now',
  imgStart: false,
  img: require('../../images/svg-3.svg'),
  alt: 'Papers',
  dark: false,
  primary: false,
  darkText: true
};

export const homeObjFour = {
  id: 'education',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Seeking opportunities of Software Development Engineer',
  headline: 'I am a full stack developer',
  description:
    "I am a part-time master student in computer science in Georgia Institute of Technology, and also an electrical and test engineer in a semiconductor company. Part of my work is related to VB6 and C programming, that's why I started to grow interest in computer science. I have broad knowledge in web development, operating system and computer network. Web development and distributed system are my center of interest, i always love the idea of easy-to-use and beautiful web app, cross-platform development, which web technology like Javascript enables me to do. ",
  buttonLabel: 'Learn More',
  imgStart: true,
  img: require('../../images/IMG_0267.JPG'),
  alt: 'Piggybank',
  dark: false,
  primary: false,
  darkText: true
};

export const homeObjFive = {
  id: 'skills',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Premium Bank',
  headline: 'Unlimited Transactions with zero fees',
  description:
    'Get access to our exclusive app that allows you to send unlimited transactions without getting charged any fees',
  buttonLabel: 'Get Started',
  imgStart: false,
  img: require('../../images/svg-1.svg'),
  alt: 'Car',
  dark: true,
  primary: true,
  darkText: false
};