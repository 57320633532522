import React from 'react';
import { Button } from '../ButtonElements';
import {Row, Col} from 'react-bootstrap';
import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  BtnWrap,
  ImgWrap,
  Img,
  Container
} from './EduElements';

const ExpSection = ({
  lightBg,
  imgStart,
  topLine,
  lightText,
  headline,
  description,
  buttonLabel,
  img,
  alt,
  id,
  primary,
  darkText,
  dark,
  dark2
}) => {
  console.log(primary);
  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        {/* <InfoWrapper> */}
        <Container>
        <Heading lightText={lightText} style={{textAlign: "center", fontSize: "48px"}}>Education</Heading>
                <TextWrapper>
                  <Row>
                    <Col sm={2}>
                    <ImgWrap start={true}>
                      <Img src='https://i.postimg.cc/LXVSYPsN/gatech.png' alt={alt} />
                    </ImgWrap>
                    </Col>
                    <Col sm={10}>
                    <TextWrapper>
                      <div style={{display: "flex", justifyContent: "space-between"}}>
                      <Heading lightText={lightText} style={{color: '#01BF71'}}>Georgia Institute of Technology</Heading>
                      <Subtitle darkText={darkText} style={{color: '#01BF71'}}>Aug. 2019 - Aug. 2020</Subtitle>
                      </div>
                      <Subtitle darkText={darkText}>Online courses, Computer Science, GPA: 4.0</Subtitle>
                    </TextWrapper>
                    </Col>
                  </Row>
                
                  <Row>
                    <Col sm={2}>
                    <ImgWrap start={true}>
                      <Img src='https://i.postimg.cc/YSGb03ZN/University-of-Southern-California.png' alt={alt} />
                    </ImgWrap>
                    </Col>
                    <Col sm={10}>
                    <TextWrapper>
                      <div style={{display: "flex", justifyContent: "space-between"}}>
                      <Heading lightText={lightText} style={{color: '#01BF71'}}>University of Southern California</Heading>
                      <Subtitle darkText={darkText} style={{color: '#01BF71'}}>2015 - 2017</Subtitle>
                      </div>
                      <Subtitle darkText={darkText}>Master of Engineering, Materials Engineering</Subtitle>
                    </TextWrapper>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={2}>
                    <ImgWrap start={true}>
                      <Img src='https://i.postimg.cc/wMmV2cD5/ustb.jpg' alt={alt} />
                    </ImgWrap>
                    </Col>
                    <Col sm={10} >
                    <TextWrapper>
                      <div style={{display: "flex", justifyContent: "space-between"}}>
                      <Heading lightText={lightText} style={{color: '#01BF71'}}>University of Science and Technology Beijing</Heading>
                      <Subtitle darkText={darkText} style={{color: '#01BF71'}}>2011 - 2015</Subtitle>
                      </div>
                      <Subtitle darkText={darkText}>Bachlor of Science, Materials Science</Subtitle>
                    </TextWrapper>
                    </Col>
                  </Row>

                </TextWrapper>
        </Container>
      </InfoContainer>
    </>
  );
};

export default ExpSection;
