import React from 'react';
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaGithub,
  FaLinkedin
} from 'react-icons/fa';
import {Row, Col} from 'react-bootstrap';
import { MdLocationOn } from 'react-icons/md';
import { BsPhone } from 'react-icons/bs';
import { FiMail } from 'react-icons/fi';
import { animateScroll as scroll } from 'react-scroll';
import {
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  Heading,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  WebsiteRights,
  SocialIcons,
  SocialIconLink,
  InfoRow,
  InfoColumn,
  TextWrapper,
  Subtitle
} from './FooterElements';

const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };
  return (
    <FooterContainer id='contact'>
      <FooterWrap>
        <Heading lightText={true} style={{textAlign: "center", fontSize: "48px", color: '#fff'}}>Get In Touch</Heading>
        <FooterLinksContainer>
          <Row>
            <Col>
            <TextWrapper>
                <Row>
                  <Col sm={3}><MdLocationOn style={{fontSize: '2em', color: 'white'}} /></Col>
                  <Col sm={9}>
                    <Row><Subtitle darkText={false}>Location</Subtitle></Row>
                    <Row><Subtitle darkText={false}>Los Angeles, CA</Subtitle></Row>
                  </Col>
                </Row>
              </TextWrapper>
            </Col>
            <Col>
            <TextWrapper>
                <Row>
                  <Col sm={3}><BsPhone style={{fontSize: '2em', color: '#fff'}} /></Col>
                  <Col sm={9}>
                    <Row><Subtitle darkText={false}>Mobile</Subtitle></Row>
                    <Row><Subtitle darkText={false}>323-405-2886</Subtitle></Row>
                  </Col>
                </Row>
              </TextWrapper>
            </Col>
            <Col>
            <TextWrapper>
              <Row>
                  <Col sm={3}><FiMail style={{fontSize: '2em', color: '#fff'}} /></Col>
                  <Col sm={9}>
                    <Row><Subtitle darkText={false}>Email</Subtitle></Row>
                    <Row><Subtitle darkText={false}>leimingcai93@gmail.com</Subtitle></Row>
                  </Col>
                </Row>
              </TextWrapper>
            </Col>
          </Row>
        </FooterLinksContainer>

        
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to='/' onClick={toggleHome}>
              Leiming Cai
            </SocialLogo>
            <WebsiteRights>Leiming Cai © 2021 All rights reserved.</WebsiteRights>
            <SocialIcons>
              <SocialIconLink href='https://www.facebook.com/profile.php?id=100006338172955' target='_blank' aria-label='Facebook'>
                <FaFacebook />
              </SocialIconLink>              
              <SocialIconLink href='https://www.linkedin.com/in/leimingcai/' target='_blank' aria-label='Linkedin'>
                <FaLinkedin />
              </SocialIconLink>
              <SocialIconLink href='https://github.com/leimingc' target='_blank' aria-label='Linkedin'>
                <FaGithub />
              </SocialIconLink>
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
